import { format, formatDistanceToNow, getTime, parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

// ----------------------------------------------------------------------

const getParsedDate = (date) => {
  if (typeof date === 'string') {
    return parseISO(date);
  }
  if (date instanceof Date && !Number.isNaN(date.valueOf())) {
    return date;
  }
  return new Date(date);
};

// ----------------------------------------------------------------------

export function fDate(date, timezone, newFormat) {
  const fm = newFormat || 'PP';

  if (timezone) {
    return date ? formatInTimeZone(getParsedDate(date), timezone, fm) : '';
  }

  return date ? format(getParsedDate(date), fm) : '';
}

export function fDateApi(date, newFormat) {
  const fm = newFormat || 'yyyy-MM-dd';

  return date ? format(getParsedDate(date), fm) : '';
}

export function fDateTime(date, timezone, newFormat) {
  const fm = newFormat || `PPp`;

  if (timezone) {
    return date ? formatInTimeZone(getParsedDate(date), timezone, fm) : '';
  }

  return date ? format(getParsedDate(date), fm) : '';
}

export function fTimestamp(date) {
  return date ? getTime(getParsedDate(date)) : '';
}

export function fToNow(date) {
  return date
    ? formatDistanceToNow(getParsedDate(date), {
        addSuffix: true,
      })
    : '';
}

export function fGetDateTime(date, timezone, newFormat) {
  const fm = newFormat || `PPp`;

  if (!date) return { date: '', time: '' };

  const parsedDate = getParsedDate(date);
  const formattedDate = timezone
    ? formatInTimeZone(parsedDate, timezone, fm)
    : format(parsedDate, fm);

  const parsedDateArray = formattedDate.split(',');

  if (parsedDateArray.length === 3) {
    const datePart = `${parsedDateArray[0].trim()}, ${parsedDateArray[1].trim()}`;
    const timePart = parsedDateArray[2].trim();
    return { date: datePart, time: timePart };
  }

  return { date: '', time: '' };
}
