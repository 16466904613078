const common = [
  'settings_personal',
  'settings_business',
  'settings_integrations',
  'settings_brand',
  'manager_user',
];

const basicFeatures = [...common, 'gift_program', 'store_credit_program'];

const standardFeatures = [
  ...common,
  'point_rules',
  'rewards_program',
  'reward_levels',
  'retention_autopilot',
  'vouchers',
  'campaigns',
  'members',
  'dashboard',
  'buy_sms_credits',
  'activity',
  'reports',
  'locations',
  'punch_cards_program',
  'referral_program',
  'vip_program',
];

const premiumFeatures = [
  ...standardFeatures,
  ...basicFeatures,
  'locations',
  'point_rules',
];

const businessPlans = {
  basic: basicFeatures,
  standard: standardFeatures,
  premium: premiumFeatures,
};

export default businessPlans;
